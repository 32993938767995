import React from "react";
import PropTypes from "prop-types";

import Logo from "components/Logo";
import "./AccessItem.scss";

const AccessItem = ({ imageFileName, imageURL, header, content }) => {

  let imagePart;
  if (imageFileName) {
    imagePart = <Logo className="access-item-image" fileName={imageFileName} />;
  }

  return (
    <>
      <a href={imageURL} target="_blank" rel="noopener noreferrer">
        {imagePart}
        <h4 className="access-item-heading">{header}</h4>
        <p>{content}</p>
      </a>
    </>
  );
};

AccessItem.propTypes = {
  imageURL: PropTypes.string,
  imageFileName: PropTypes.string,
  header: PropTypes.string,
  content: PropTypes.string,
};

AccessItem.defaultProps = {
  imageURL: null,
  imageFileName: null,
  header: "",
  content: "",
};

export default AccessItem;
