import React from "react";
import PropTypes from "prop-types";

import { Button } from "react-bootstrap";
import ImageCard from "components/ImageCard";
import useSmoothScrollTo from "hooks/useSmoothScrollTo";

const Top = ({ frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { header, subheader, imageFileName, urlFirst, urlFirstText, urlSecond, urlSecondText } = frontmatter;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const scrollToSection = useSmoothScrollTo(urlFirst);

  let extraInfoPart1;
  if (urlFirst && urlFirstText) {
    extraInfoPart1 = (
      <a>
        <Button size="xl" variant="primary" onClick={scrollToSection}>
          {urlFirstText}
        </Button>
      </a>
    );
  }

  let extraInfoPart2;
  if (urlSecond && urlSecondText) {
    extraInfoPart2 = (
      <a href={urlSecond}>
        <Button size="xl" variant="secondary">
          {urlSecondText}
        </Button>
      </a>
    );
  }

  return (
    <ImageCard
      imageFileName={imageFileName}
      header={header}
      subheader={subheader}
      extraInfo1={extraInfoPart1}
      extraInfo2={extraInfoPart2}
    />
  );
};

Top.propTypes = {
  frontmatter: PropTypes.object,
};

Top.defaultProps = {
  frontmatter: null,
};

export default Top;
